"use strict";

export class SearchVehiclesByFeature {
  constructor() {
    // Ads or removes classes for the table according to the list that has been clicked
    $("body").on("click", ".columns__expand-btn", function(e) {
      e.preventDefault();
      if ($(this).hasClass("active")) {
        $(this)
          .parents(".columns")
          .find(">.column")
          .slideUp();
        $(this).attr("aria-expanded", "false");
        $(this).removeClass("active");
      } else {
        $(this)
          .parents(".columns")
          .find(">.column")
          .slideDown();
        $(this).attr("aria-expanded", "true");
        $(this).addClass("active");
      }
    });
  }
}
