"use strict";

export class Fuel_lifecycle_emissions_calculator_page {
  constructor() {
    // Checks if fuel and co2 calculator is on current page
    if ($(".fuel-lifecycle-emissions-calculator").length) {
      var currentFuelValue, currentEmissionFactor;

      const fuelLifecycleEmissionsButton = document.querySelector(
        ".fuel-lifecycle-emissions-calculator__btn"
      );
      const fuelLifecycleEmissionsValues = document.querySelector(
        ".fuel-lifecycle-emissions-calculator__value"
      );

      const divFuelConsumption = document.querySelector(
        ".fuel-lifecycle-emissions-calculator__fuel-consumption"
      );
      const divFuelType = document.querySelector(
        "#fuel-lifecycle-emissions-calculator__fuel-type"
      );
      const divEnergyConsumption = document.querySelector(
        ".fuel-lifecycle-emissions-calculator__energy-consumption"
      );
      const divElectricityGrid = document.querySelector(
        "#fuel-lifecycle-emissions-calculator__electricity-grid"
      );
      const divKmTravelled = document.querySelector(
        ".fuel-lifecycle-emissions-calculator__km-travelled"
      );
      $("#fuel-lifecycle-emissions-calculator__fuel-type").change(function() {
        //Dropdown default "All" items
        if ($(this).val() === "Petrol") {
          currentFuelValue = 2436;
        }
        if ($(this).val() === "Diesel") {
          currentFuelValue = 2861;
        }
        if ($(this).val() === "LPG") {
          currentFuelValue = 1690;
        }
        $(this)
          .closest(".fuel-lifecycle-emissions-calculator__box")
          .addClass("hasValue");
        if ($(this).val() === "FuelType_NS") {
          $(this)
            .closest(".fuel-lifecycle-emissions-calculator__box")
            .removeClass("hasValue");
        }
      });
      $("#fuel-lifecycle-emissions-calculator__electricity-grid").change(
        function() {
          //Dropdown default "All" items
          if ($(this).val() === "ACT") {
            currentEmissionFactor = 0.85;
          }
          if ($(this).val() === "NSW") {
            currentEmissionFactor = 0.85;
          }
          if ($(this).val() === "NT") {
            currentEmissionFactor = 0.58;
          }
          if ($(this).val() === "QLD") {
            currentEmissionFactor = 0.92;
          }
          if ($(this).val() === "SA") {
            currentEmissionFactor = 0.36;
          }
          if ($(this).val() === "TAS") {
            currentEmissionFactor = 0.16;
          }
          if ($(this).val() === "VIC") {
            currentEmissionFactor = 1.0;
          }
          if ($(this).val() === "WA") {
            currentEmissionFactor = 0.69;
          }
          $(this)
            .closest(".fuel-lifecycle-emissions-calculator__box")
            .addClass("hasValue");
          if ($(this).val() === "ElectricityGrid_NS") {
            $(this)
              .closest(".fuel-lifecycle-emissions-calculator__box")
              .removeClass("hasValue");
          }
        }
      );

      // Checks to see when the calculate button is clicked and gets input from users "input" field
      fuelLifecycleEmissionsButton.addEventListener("click", function(e) {
        var valueFuelConsumption = document.querySelector(
          ".fuel-lifecycle-emissions-calculator__fuel-consumption"
        ).value;
        var valueEnergyConsumption = document.querySelector(
          ".fuel-lifecycle-emissions-calculator__energy-consumption"
        ).value;
        var valueKmTravelled = document.querySelector(
          ".fuel-lifecycle-emissions-calculator__km-travelled"
        ).value;

        var fuelLifecycle, annualLifecycle;
        e.preventDefault();

        divFuelConsumption.classList.remove("error-active");
        divFuelType.classList.remove("error-active-v2");
        divEnergyConsumption.classList.remove("error-active");
        divElectricityGrid.classList.remove("error-active-v2");
        divKmTravelled.classList.remove("error-active");
        fuelLifecycleEmissionsValues.classList.remove(
          "fuel-lifecycle-emissions-calculator__value-active"
        );

        // Makes sure all the fields are active and not empty
        if (
          valueFuelConsumption &&
          valueEnergyConsumption &&
          valueKmTravelled &&
          $("#fuel-lifecycle-emissions-calculator__fuel-type").val() &&
          $("#fuel-lifecycle-emissions-calculator__electricity-grid").val()
        ) {
          //Calculates fuel cost and annual CO2
          fuelLifecycleEmissionsValues.classList.add(
            "fuel-lifecycle-emissions-calculator__value-active"
          );

          fuelLifecycle =
            (valueFuelConsumption / 100) * currentFuelValue +
            valueEnergyConsumption * currentEmissionFactor;
          fuelLifecycle = Math.round(fuelLifecycle * 100) / 100;

          annualLifecycle = (fuelLifecycle * valueKmTravelled) / 1000000;
          annualLifecycle = Math.round(annualLifecycle * 100) / 100 + " tonnes";

          $(".fuel-lifecycle-emissions-calculator__value__fuel-lifecycle").html(
            fuelLifecycle
          );
          $(
            ".fuel-lifecycle-emissions-calculator__value__annual-lifecycle"
          ).html(annualLifecycle);
        }
        // Else add error active states to fields that are empty
        else {
          if (!valueFuelConsumption) {
            divFuelConsumption.classList.add("error-active");
          }
          if (!valueEnergyConsumption) {
            divEnergyConsumption.classList.add("error-active");
          }
          if (!valueKmTravelled) {
            divKmTravelled.classList.add("error-active");
          }
          if (!$("#fuel-lifecycle-emissions-calculator__fuel-type").val()) {
            divFuelType.classList.add("error-active-v2");
          }
          if (
            !$("#fuel-lifecycle-emissions-calculator__electricity-grid").val()
          ) {
            divElectricityGrid.classList.add("error-active-v2");
          }
        }
      });
      // Moves text above input when user types in a number
      $(".fuel-lifecycle-emissions-calculator__box input").on(
        "change keyup keydown",
        function(e) {
          var elem = $(this);
          if (elem.val()) {
            elem
              .closest(".fuel-lifecycle-emissions-calculator__box")
              .addClass("hasValue");
          } else {
            elem
              .closest(".fuel-lifecycle-emissions-calculator__box")
              .removeClass("hasValue");
          }
        }
      );
    }
  }
}
