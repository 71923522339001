"use strict";

export class Footer {
  constructor() {
    const footerMenuBtn = document.querySelector(".footer__menu__btn");
    // show dropdown when footer menu btn is clicked
    $("body").on("click", ".footer__h4", function(e) {
      //Slide up when clicked and slide active
      e.preventDefault();
      if (
        $(this)
          .find(".footer__menu__btn")
          .hasClass("Slide-active")
      ) {
        $(this)
          .siblings(".footer__link__dropdown")
          .slideUp();
        footerMenuBtn.setAttribute("aria-expanded", "false");
        $(this)
          .find(".footer__menu__btn")
          .removeClass("Slide-active")
          .removeClass("rotate-btn");
      }
      //Slide down when clicked and slide is not active
      else {
        $(this)
          .siblings(".footer__link__dropdown")
          .slideDown();
        footerMenuBtn.setAttribute("aria-expanded", "true");
        $(this)
          .find(".footer__menu__btn")
          .addClass("Slide-active")
          .addClass("rotate-btn");
      }
    });
  }
}
