"use strict";

export class SearchVehiclesByFeaturePageSearchTableBlock {
  constructor() {
    //checks window size and creates dropdown menu accordingly
    var windowsize = $(window).width();
    windowsize = $(window).width();
    if (windowsize < 769) {
      $(".compare-by-expand-btn")
        .siblings(".checkboxes-wrapper")
        .slideUp();
      $(".compare-by-expand-btn").attr("aria-expanded", "false");
      $(".compare-by-expand-btn").removeClass("active");
      $("body").on("click", ".compare-by-expand-btn", function(e) {
        e.preventDefault();
        if ($(this).hasClass("active")) {
          $(this)
            .siblings(".checkboxes-wrapper")
            .slideUp();
          $(this).attr("aria-expanded", "false");
          $(this).removeClass("active");
        } else {
          $(this)
            .siblings(".checkboxes-wrapper")
            .slideDown();
          $(this).attr("aria-expanded", "true");
          $(this).addClass("active");
        }
      });
    }

    // // to get the first number in a string
    // function getFirstNumber(string) {
    //   let $firstCell = 0;
    //   for (let i = 0; i < string.length; i++) {
    //     if (string.charAt(i) === "$") continue;
    //     if (
    //       !(
    //         (string.charAt(i) >= "0" && string.charAt(i) <= "9") ||
    //         string.charAt(i) === "."
    //       )
    //     )
    //       return parseFloat($firstCell);

    //     $firstCell += string.charAt(i);
    //   }
    //   return parseFloat($firstCell);
    // }

    // // set scale class on the item
    // function setScale(average, min, diff, $cell) {
    //   let cellVal = getFirstNumber($cell.text());
    //   let scale1 = min + diff * 1;
    //   let scale2 = min + diff * 2;
    //   let scale3 = min + diff * 3;
    //   let scale4 = min + diff * 4;
    //   let scale5 = min + diff * 5;

    //   if (cellVal == average) {
    //     $cell.addClass("scale3");
    //     $cell.append('<span class="scale-text"> [Average]</span>');
    //   } else {
    //     //scale1 is best, scale5 is the worst
    //     if (cellVal >= min && cellVal < scale1) {
    //       $cell.addClass("scale1");
    //       $cell.append(
    //         '<span class="scale-text"> [Better than average]</span>'
    //       );
    //     } else if (cellVal >= scale1 && cellVal <= scale2) {
    //       $cell.addClass("scale2");
    //       $cell.append(
    //         '<span class="scale-text"> [Slightly better than average]</span>'
    //       );
    //     } else if (cellVal >= scale2 && cellVal <= scale3) {
    //       $cell.addClass("scale3");
    //       $cell.append('<span class="scale-text"> [Average]</span>');
    //     } else if (cellVal >= scale3 && cellVal <= scale4) {
    //       $cell.addClass("scale4");
    //       $cell.append(
    //         '<span class="scale-text"> [Slightly worse than average]</span>'
    //       );
    //     } else if (cellVal >= scale4 && cellVal <= scale5) {
    //       $cell.addClass("scale5");
    //       $cell.append('<span class="scale-text"> [Worse than average]</span>');
    //     }
    //   }
    // }

    // // compute scale background
    // function setBackgroundColour() {
    //   $(".to-compare")
    //     .removeClass("scale1")
    //     .removeClass("scale2")
    //     .removeClass("scale3")
    //     .removeClass("scale4")
    //     .removeClass("scale5");

    //   $(".scale-text").remove();
    //   let toCompareCellCounter = 0;
    //   let $toCompareItems = $(".vehicle-item")
    //     .first()
    //     .find(".to-compare");
    //   for (let i = 0; i < $toCompareItems.length; i++) {
    //     //tocompare cells
    //     let vehicleCounter = 0;
    //     let $firstCell = $(
    //       $($(".vehicle-item").get(vehicleCounter))
    //         .find(".to-compare")
    //         .get(toCompareCellCounter)
    //     );
    //     let sum = 0;
    //     let $maxValCell = $firstCell;
    //     let $minValCell = $firstCell;
    //     let naCounter = 0;

    //     for (let y = 0; y < $(".vehicle-item").length; y++) {
    //       let $cell = $(
    //         $($(".vehicle-item").get(vehicleCounter))
    //           .find(".to-compare")
    //           .get(toCompareCellCounter)
    //       );

    //       if ($cell.text() === "N/A") {
    //         naCounter++;
    //       }
    //       vehicleCounter++;

    //       if (
    //         getFirstNumber($cell.text()) > getFirstNumber($maxValCell.text())
    //       ) {
    //         $maxValCell = $cell;
    //         $cell.addClass("scale");
    //       } else if (
    //         getFirstNumber($cell.text()) < getFirstNumber($minValCell.text())
    //       ) {
    //         $minValCell = $cell;
    //       }
    //       sum += getFirstNumber($cell.text());
    //     }

    //     let averageVal = sum / (vehicleCounter - naCounter);
    //     let minVal = getFirstNumber($minValCell.text());
    //     let maxVal = getFirstNumber($maxValCell.text());
    //     let maxMinDiff = (maxVal - minVal) / 5;

    //     vehicleCounter = 0;
    //     for (let y = 0; y < $(".vehicle-item").length; y++) {
    //       let $cell = $(
    //         $($(".vehicle-item").get(vehicleCounter))
    //           .find(".to-compare")
    //           .get(toCompareCellCounter)
    //       );
    //       if ($cell.text() === "N/A") {
    //       } else {
    //         setScale(averageVal.toPrecision(12), minVal, maxMinDiff, $cell);
    //       }
    //       vehicleCounter++;
    //     }

    //     toCompareCellCounter++;
    //     // console.log("min",minVal, "max",maxVal, "aver", averageVal, "diff", maxMinDiff,'counter', vehicleCounter)
    //   }
    // }

    // to remove an array item
    function removeArrayItem(arr, item) {
      var removeItem = item;

      arr = jQuery.grep(arr, function(value) {
        return value != removeItem;
      });
      return arr;
    }

    // generate checkbox filters
    $(".results-table-block .table-wrapper thead tr:first-child th").each(
      function() {
        let thClassList = $(this)
          .attr("class")
          .split(/\s+/);
        let tooltipText = $(this).attr("data-tooltip");
        thClassList = removeArrayItem(thClassList, "no-sort");
        thClassList = removeArrayItem(thClassList, "always-show");
        thClassList = removeArrayItem(thClassList, "hidden");

        let firstClass = thClassList[0];
        let columnText = (
          firstClass.charAt(0).toUpperCase() + firstClass.slice(1)
        )
          .replaceAll("-", " ")
          .replaceAll("co2", "CO<sub>2</sub>");

        let checkboxHtml =
          '<div class="checkbox-item-wrapper"><label for="' +
          firstClass +
          '"><input checked id="' +
          firstClass +
          '" type="checkbox" name="' +
          firstClass +
          '" value="' +
          firstClass +
          '">' +
          columnText +
          "</label><div class='tool-tip'><img src='/assets/images/Function_icons/Tooltip_small.svg' alt='Tool tip icon'><span>" +
          tooltipText +
          "</span></div></div>";
        $(".checkboxes-wrapper").append(checkboxHtml);
      }
    );

    // on change for checkbox filters
    $("body").on("change", ".compare-by-block input", function(e) {
      let val = $(this).attr("value");
      if (!$(this).is(":checked")) {
        $("." + val + ":not(.always-show)").addClass("hidden");
        if (val === "details") {
          $(".table-wrapper thead tr:first-child th:first-child").attr(
            "colspan",
            "1"
          );
        }
      } else {
        $("." + val + ":not(.always-show)").removeClass("hidden");
        if (val === "details") {
          $(".table-wrapper thead tr:first-child th:first-child").attr(
            "colspan",
            "5"
          );
        }
      }
    });

    // setBackgroundColour();

    // next column
    $("body").on("click", ".search__btn__next", function(e) {
      e.preventDefault();

      let $table = $(".results-table-block .results-content .table-wrapper");
      let currentPosition = $table.scrollLeft() + 1;
      let nextPosition = $table.scrollLeft() + 1;

      $table.find("thead tr:first-child th").each(function() {
        if (currentPosition < $(this).position().left) {
          nextPosition = $(this).position().left;
          return false;
        }
      });

      $table.animate(
        {
          scrollLeft: nextPosition
        },
        500
      );
    });

    // prev column

    $("body").on("click", ".search__btn__prev", function(e) {
      e.preventDefault();
      let $table = $(".results-table-block .results-content .table-wrapper");
      let currentPosition = $table.scrollLeft() - 1;
      let nextPosition = $table.scrollLeft() - 1;
      $(
        $table
          .find("thead tr:first-child th")
          .get()
          .reverse()
      ).each(function() {
        if (currentPosition > $(this).position().left) {
          nextPosition = $(this).position().left;

          return false;
        }
      });
      $table.animate(
        {
          scrollLeft: nextPosition
        },
        500
      );
    });
  }
}
