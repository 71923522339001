// Main javascript entry point
// Should handle bootstrapping/starting application

"use strict";

import "core-js";
import "regenerator-runtime/runtime";
import $ from "jquery";
import { Link } from "../_modules/link/link";
import { TopPerformers } from "../_modules/top-performers/top-performers";
import { Search } from "../_modules/search/search";
import { Navigation } from "../_modules/navigation/navigation";
import { Header } from "../_modules/header/header";
import { Footer } from "../_modules/footer/footer";
import { Getting_started } from "../_modules/getting-started/getting-started";
import { ResultsPageCompareVehiclesBlock } from "../_modules/results-page-compare-vehicles-block/results-page-compare-vehicles-block";
import { ResultsPageResultsBlock } from "../_modules/results-page-results-block/results-page-results-block";
import { Home_charging_calculator } from "../_modules/home-charging-calculator/home-charging-calculator";
import { Fuel_and_CO2_calculator } from "../_modules/fuel-and-CO2/fuel-and-CO2";
import { Fuel_lifecycle_emissions_calculator_page } from "../_modules/fuel-lifecycle-emissions-calculator-page/fuel-lifecycle-emissions-calculator-page";
import { FeedbackBox } from "../_modules/feedback-box/feedback-box";
import { GlobalElements } from "../_modules/global-elements/global-elements";
import { SearchVehiclesByFeaturePageSearchTableBlock } from "../_modules/search-vehicles-by-feature-page-search-table-block/search-vehicles-by-feature-page-search-table-block";
import { SearchVehiclesByFeature } from "../_modules/search-vehicles-by-feature/search-vehicles-by-feature";
import { News } from "../_modules/news/news";
import { NewsPage } from "../_modules/news-page/news-page";
import { IndividualPage } from "../_modules/individual-page-results-block/individual-page-results-block";
$(() => {
  new Link(); // Activate Link modules logic
  new TopPerformers();
  new Search();
  new Navigation();
  new Header();
  new Footer();
  new Getting_started();
  new ResultsPageCompareVehiclesBlock();
  new ResultsPageResultsBlock();
  new Home_charging_calculator();
  new Fuel_and_CO2_calculator();
  new Fuel_lifecycle_emissions_calculator_page();
  new FeedbackBox();
  new GlobalElements();
  new SearchVehiclesByFeaturePageSearchTableBlock();
  new News();
  new SearchVehiclesByFeature();
  new NewsPage();
  new IndividualPage();
});
