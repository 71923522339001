"use strict";

export class Navigation {
  constructor() {
    const nav = document.querySelector(".nav");
    const navMenu = document.querySelector(".nav__menu");
    const navMenuClose = document.querySelector(".nav__menu__close");
    const navLink = document.querySelectorAll(".nav__link");
    if ($(window).width() > 1017) {
      navLink.forEach(menuButton => {
        menuButton.addEventListener("click", e => {
          const navDropDown = e.currentTarget.children[1];
          const navChevron = e.currentTarget.children[0].children[0];
          const navLink = e.currentTarget;
          if (navDropDown.classList.contains("addmenu")) {
            navDropDown.classList.remove("addmenu");
            navChevron.classList.remove("btn-rotate");
            navLink.setAttribute("aria-expanded", "false");
            navDropDown.setAttribute("aria-expanded", "false");
          } else {
            navDropDown.classList.add("addmenu");
            navChevron.classList.add("btn-rotate");
            navLink.setAttribute("aria-expanded", "true");
            navDropDown.setAttribute("aria-expanded", "true");
          }
        });
      });
    }
    //Checks screen size, if less than 1017 show mobile menu
    if ($(window).width() < 1017) {
      // When nav menu is clicked, show nav menu
      navMenu.addEventListener("click", function(e) {
        e.preventDefault();
        nav.classList.add("mobile-nav-active");
        $(".nav__link__menu_level_1").slideUp();
      });
      // Close nav menu when close button is clicked
      navMenuClose.addEventListener("click", function(e) {
        e.preventDefault();
        nav.classList.remove("mobile-nav-active");
      });

      // When nav menu dropdown is clicked, slide down the nav links, and slide up when slide is active
      $("body").on("click", ".nav__link", function(e) {
        const navDropDown = e.currentTarget.children[1];
        const navChevron = e.currentTarget.children[0].children[0];
        const navLink = e.currentTarget;
        if (
          $(this)
            .find(".nav__item")
            .hasClass("Slide-active")
        ) {
          $(this)
            .children(".nav__link__menu_level_1")
            .slideUp();

          $(this)
            .find(".nav__item")
            .removeClass("Slide-active");

          navChevron.classList.remove("chevron-rotate");
          navLink.setAttribute("aria-expanded", "false");
          navDropDown.setAttribute("aria-expanded", "false");
        } else {
          $(this)
            .children(".nav__link__menu_level_1")
            .slideDown();
          $(this)
            .find(".nav__item")
            .addClass("Slide-active");
          navChevron.classList.add("chevron-rotate");
          navLink.setAttribute("aria-expanded", "true");
          navDropDown.setAttribute("aria-expanded", "true");
        }
      });
    }
  }
}
