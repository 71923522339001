"use strict";

export class NewsPage {
  constructor() {
    //Checks if the page loaded has news content
    if ($(".news-page").length) {
      var numOfNews,
        numOfPages,
        NumOfBoxes,
        sortVar,
        currentPage,
        dateSortedLatest,
        dateSortedOldest;

      function showFirstNumOfBoxes() {
        //shows the first NumOfBoxes boxes of content in page 1
        $(".news-page__section__box").hide();
        $(".news-page__section__box")
          .slice(0, NumOfBoxes)
          .show();
        $(".news-page__additional:eq(0)").css("text-decoration", "underline");
        $(".news-page__additional:eq(0)").css("color", "black");
      }

      function calculatePages() {
        //Checks number of pages to load given the amount of news
        numOfNews = $(".news-page__section__box").length;
        numOfPages = Math.ceil(numOfNews / NumOfBoxes);
        //Calculates number of pages to create on the bottom
        for (let i = 0; i < numOfPages - 1; i++) {
          console.log("test");
          $(".news-page__bottom").append(
            "<a class='news-page__additional' >" + (i + 2) + "</a>"
          );
        }
      }
      //Filters the news according to dropdown selection
      $("#news-page__filter").change(function() {
        //Dropdown default "All" items
        if ($(this).val() === "All") {
          currentPage = 1;
          sortVar = "All";
          $(".news-page__additional").remove();
          $(".news-page__bottom").append(
            "<a class='news-page__additional' >" + 1 + "</a>"
          );
          calculatePages();

          showFirstNumOfBoxes();

          $(".auto-height").matchHeight();
          activatePageNum();
        }
        // Dropdown for Latest news, ascending order for date
        if ($(this).val() === "Latest") {
          currentPage = 1;
          sortVar = "All";
          $(".news-page__additional").remove();
          $(".news-page__bottom").append(
            "<a class='news-page__additional' >" + 1 + "</a>"
          );
          calculatePages();

          if (dateSortedLatest === false) {
            //Sort By Ascending Data (Default)
            var container = $(".news-page");
            var items = $(".news-page__section__box");
            $(".news-page__section__box").show();

            items.each(function() {
              // Convert the string in 'data-event-date' attribute to a more
              // standardized date format
              var BCDate = $(this)
                .attr("data-event-date")
                .split("-");
              var standardDate = BCDate[1] + " " + BCDate[0] + " " + BCDate[2];
              standardDate = new Date(standardDate).getTime();
              $(this).attr("data-event-date", standardDate);
            });

            items
              .sort(function(a, b) {
                a = parseFloat($(a).attr("data-event-date"));
                b = parseFloat($(b).attr("data-event-date"));
                return a < b ? -1 : a > b ? 1 : 0;
              })
              .each(function() {
                container.prepend(this);
              });
            dateSortedLatest = true;
            dateSortedOldest = false;
          }

          showFirstNumOfBoxes();
          activatePageNum();

          $(".auto-height").matchHeight();
        }
        // Dropdown for Oldest news, descending order for date
        if ($(this).val() === "Oldest") {
          currentPage = 1;
          sortVar = "All";
          $(".news-page__additional").remove();
          $(".news-page__bottom").append(
            "<a class='news-page__additional' >" + 1 + "</a>"
          );
          calculatePages();

          if (dateSortedOldest === false) {
            //Sort By Ascending Data (Default)
            var container = $(".news-page");
            var items = $(".news-page__section__box");
            $(".news-page__section__box").show();

            items.each(function() {
              // Convert the string in 'data-event-date' attribute to a more
              // standardized date format
              var BCDate = $(this)
                .attr("data-event-date")
                .split("-");
              var standardDate = BCDate[1] + " " + BCDate[0] + " " + BCDate[2];
              standardDate = new Date(standardDate).getTime();
              $(this).attr("data-event-date", standardDate);
            });

            items
              .sort(function(a, b) {
                a = parseFloat($(a).attr("data-event-date"));
                b = parseFloat($(b).attr("data-event-date"));
                return a > b ? -1 : a < b ? 1 : 0;
              })
              .each(function() {
                container.prepend(this);
              });
            dateSortedOldest = true;
            dateSortedLatest = false;
          }

          showFirstNumOfBoxes();
          activatePageNum();

          $(".auto-height").matchHeight();
        }
        // Dropdown for only News items
        if ($(this).val() === "News") {
          //Sort By Case study only
          $(".news-page__section__box").hide();
          $(".news-page__section__box[data-event-type='News']")
            .slice(0, NumOfBoxes)
            .show();

          $(".news-page__additional").remove();
          $(".news-page__bottom").append(
            "<a class='news-page__additional' >" + 1 + "</a>"
          );
          currentPage = 1;

          //Checks number of pages to load given the amount of news
          numOfNews = $(".news-page__section__box[data-event-type='News']")
            .length;
          console.log(numOfNews);
          numOfPages = Math.ceil(numOfNews / NumOfBoxes);
          console.log(numOfPages, currentPage);
          //Calculates number of pages to create on the bottom
          for (let i = 0; i < numOfPages - 1; i++) {
            console.log("test");
            $(".news-page__bottom").append(
              "<a class='news-page__additional' >" + (i + 2) + "</a>"
            );
          }

          sortVar = "news";
          $(".news-page__additional:eq(0)").css("text-decoration", "underline");
          $(".news-page__additional:eq(0)").css("color", "black");
          activatePageNum();
        }
        // Dropdown for only case study
        if ($(this).val() === "Case-study") {
          //Sort By Case study only
          $(".news-page__section__box").hide();
          $(".news-page__section__box[data-event-type='Case-study']")
            .slice(0, NumOfBoxes)
            .show();

          $(".news-page__additional").remove();
          $(".news-page__bottom").append(
            "<a class='news-page__additional' >" + 1 + "</a>"
          );
          currentPage = 1;

          //Checks number of pages to load given the amount of news
          numOfNews = $(
            ".news-page__section__box[data-event-type='Case-study']"
          ).length;
          numOfPages = Math.ceil(numOfNews / NumOfBoxes);
          //Calculates number of pages to create on the bottom
          for (let i = 0; i < numOfPages - 1; i++) {
            console.log("test");
            $(".news-page__bottom").append(
              "<a class='news-page__additional' >" + (i + 2) + "</a>"
            );
          }

          sortVar = "case-study";
          $(".news-page__additional:eq(0)").css("text-decoration", "underline");
          $(".news-page__additional:eq(0)").css("color", "black");
          activatePageNum();
        }
      });

      //if next button is clicked, change to next page, next set of content
      $(".news-page__next").on("click", function(e) {
        if (currentPage !== numOfPages) {
          console.log("test", numOfPages, currentPage);
          console.log("SortVar2", sortVar, sortVar === "case-study");

          if (sortVar === "All") {
            $(".news-page__section__box")
              .slice(
                currentPage * NumOfBoxes - NumOfBoxes,
                currentPage * NumOfBoxes
              )
              .hide();
            $(".news-page__section__box")
              .slice(
                currentPage * NumOfBoxes,
                currentPage * NumOfBoxes + NumOfBoxes
              )
              .show();
          } else if (sortVar === "case-study") {
            console.log("Test Works ");
            $(".news-page__section__box[data-event-type='Case-study']")
              .slice(
                currentPage * NumOfBoxes - NumOfBoxes,
                currentPage * NumOfBoxes
              )
              .hide();
            $(".news-page__section__box[data-event-type='Case-study']")
              .slice(
                currentPage * NumOfBoxes,
                currentPage * NumOfBoxes + NumOfBoxes
              )
              .show();
          } else if (sortVar === "news") {
            console.log("Test Works ");
            $(".news-page__section__box[data-event-type='News']")
              .slice(
                currentPage * NumOfBoxes - NumOfBoxes,
                currentPage * NumOfBoxes
              )
              .hide();
            $(".news-page__section__box[data-event-type='News']")
              .slice(
                currentPage * NumOfBoxes,
                currentPage * NumOfBoxes + NumOfBoxes
              )
              .show();
          }

          $(".news-page__additional:eq(" + (currentPage - 1) + ")").css(
            "text-decoration",
            "none"
          );
          $(".news-page__additional:eq(" + (currentPage - 1) + ")").css(
            "color",
            "#01654A"
          );

          currentPage++;
          console.log(currentPage, numOfPages);

          $(".news-page__additional:eq(" + (currentPage - 1) + ")").css(
            "text-decoration",
            "underline"
          );
          $(".news-page__additional:eq(" + (currentPage - 1) + ")").css(
            "color",
            "black"
          );
        }
      });
      //if previous button is clicked, change to previous page, previous set of content
      $(".news-page__previous").on("click", function(e) {
        e.preventDefault();
        if (currentPage !== 1) {
          if (sortVar === "All") {
            $(".news-page__section__box")
              .slice(
                currentPage * NumOfBoxes - NumOfBoxes,
                currentPage * NumOfBoxes
              )
              .hide();
            $(".news-page__section__box")
              .slice(
                currentPage * NumOfBoxes - 24,
                currentPage * NumOfBoxes - NumOfBoxes
              )
              .show();
          } else if (sortVar === "case-study") {
            $(".news-page__section__box[data-event-type='Case-study']")
              .slice(
                currentPage * NumOfBoxes - NumOfBoxes,
                currentPage * NumOfBoxes
              )
              .hide();
            $(".news-page__section__box[data-event-type='Case-study']")
              .slice(
                currentPage * NumOfBoxes - 24,
                currentPage * NumOfBoxes - NumOfBoxes
              )
              .show();
          } else if (sortVar === "news") {
            $(".news-page__section__box[data-event-type='News']")
              .slice(
                currentPage * NumOfBoxes - NumOfBoxes,
                currentPage * NumOfBoxes
              )
              .hide();
            $(".news-page__section__box[data-event-type='News']")
              .slice(
                currentPage * NumOfBoxes - 24,
                currentPage * NumOfBoxes - NumOfBoxes
              )
              .show();
          }

          $(".news-page__additional:eq(" + (currentPage - 1) + ")").css(
            "text-decoration",
            "none"
          );
          $(".news-page__additional:eq(" + (currentPage - 1) + ")").css(
            "color",
            "#01654A"
          );

          currentPage--;
          console.log(currentPage, numOfPages);

          $(".news-page__additional:eq(" + (currentPage - 1) + ")").css(
            "text-decoration",
            "underline"
          );
          $(".news-page__additional:eq(" + (currentPage - 1) + ")").css(
            "color",
            "black"
          );
        }
      });
      //if number is clicked, changed to according page
      function activatePageNum() {
        $(".news-page__additional").on("click", function(e) {
          var sd = $(this)
            .text()
            .replace(/[^0-9]/gi, "");
          var number = parseInt(sd, 10);
          console.log(number);

          $(".news-page__additional:eq(" + (currentPage - 1) + ")").css(
            "text-decoration",
            "none"
          );
          $(".news-page__additional:eq(" + (currentPage - 1) + ")").css(
            "color",
            "#01654A"
          );

          currentPage = number;

          $(".news-page__section__box").hide();
          $(".news-page__section__box")
            .slice(
              currentPage * NumOfBoxes - NumOfBoxes,
              currentPage * NumOfBoxes
            )
            .show();

          $(".news-page__additional:eq(" + (currentPage - 1) + ")").css(
            "text-decoration",
            "underline"
          );
          $(".news-page__additional:eq(" + (currentPage - 1) + ")").css(
            "color",
            "black"
          );
        });
      }
      //Show first page of blocks and initialisation

      if ($(window).width() < 600) {
        NumOfBoxes = 6;
      } else if ($(window).width() < 1200) {
        NumOfBoxes = 8;
      } else {
        NumOfBoxes = 12;
      }

      currentPage = 1;
      dateSortedLatest = false;
      dateSortedOldest = false;
      sortVar = "All";
      calculatePages();
      showFirstNumOfBoxes();
      activatePageNum();
    }
  }
}
