"use strict";

export class Home_charging_calculator {
  constructor() {
    // Checks if home charging calculator page is present
    if ($(".home-charging-calculator").length) {
      const chargingButton = document.querySelector(
        ".home-charging-calculator__btn"
      );
      const homeCharging = document.querySelector(
        ".home-charging-calculator__value"
      );
      const divRate = document.querySelector(".home-charging-calculator__rate");
      const divBattery = document.querySelector(
        ".home-charging-calculator__battery"
      );
      const divElectricity = document.querySelector(
        ".home-charging-calculator__electricity"
      );
      // Checks with calculate button is clicked
      chargingButton.addEventListener("click", function(e) {
        var valueRate = document.querySelector(
          ".home-charging-calculator__rate"
        ).value;
        var valueBattery = document.querySelector(
          ".home-charging-calculator__battery"
        ).value;
        var valueElectricity = document.querySelector(
          ".home-charging-calculator__electricity"
        ).value;
        var chargingTime, chargingCost;
        e.preventDefault();

        divRate.classList.remove("error-active");
        divBattery.classList.remove("error-active");
        divElectricity.classList.remove("error-active");
        homeCharging.classList.remove("home-charging-calculator__value-active");

        // Makes sure the input isnt empty
        if (valueRate && valueBattery && valueElectricity) {
          homeCharging.classList.add("home-charging-calculator__value-active");
          chargingTime = valueBattery / valueRate;
          var hrs = parseInt(Number(chargingTime));
          var min = Math.round((Number(chargingTime) - hrs) * 60);
          var chargingTimeText = hrs + " hours, " + min + " minutes";

          chargingCost =
            "$" +
            Math.round(((valueBattery * valueElectricity) / 100) * 100) / 100;

          $(".home-charging-calculator__value__charging-time").html(
            chargingTimeText
          );
          $(".home-charging-calculator__value__charging-cost").html(
            chargingCost
          );
        }
        // Shows error states when input is empty
        else {
          if (!valueRate) {
            divRate.classList.add("error-active");
          }
          if (!valueBattery) {
            divBattery.classList.add("error-active");
          }
          if (!valueElectricity) {
            divElectricity.classList.add("error-active");
          }
        }
      });
      // Slides text above input when text is entered into box
      $(".home-charging-calculator__box input").on(
        "change keyup keydown",
        function(e) {
          var elem = $(this);
          if (elem.val()) {
            elem.closest(".home-charging-calculator__box").addClass("hasValue");
          } else {
            elem
              .closest(".home-charging-calculator__box")
              .removeClass("hasValue");
          }
        }
      );
    }
  }
}
