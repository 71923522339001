"use strict";

export class ResultsPageCompareVehiclesBlock {
  constructor() {
    // Matches height of compare box
    $(
      ".vehicles-compare .column.thirds:not(.hidden), .additional"
    ).matchHeight();
    //checks number of compare vehicle boxes present
    function checkVehicleNumber() {
      if ($(".vehicles-compare .column.thirds:not(.hidden)").length > 2) {
        $(".additional").addClass("hidden");
      } else {
        $(".additional").removeClass("hidden");
      }
    }
    checkVehicleNumber();

    $("body").on("click", ".additional", function(e) {
      e.preventDefault();

      $(".column.thirds.hidden")
        .first()
        .removeClass("hidden");
      checkVehicleNumber();
    });
    //Removes compare box
    $("body").on("click", ".removeBtn", function(e) {
      e.preventDefault();

      //reset column vals when remove is clicked
      var el = $(this)
        .parent()
        .parent()
        .parent()
        .attr("class");

      var column = Number(el.substr(el.length - 1)) - 1;
      $("#VS_" + column + "_SelectedYearStart").prop("selectedIndex", 0);
      $("#VS_" + column + "_SelectedManufacturer").prop("selectedIndex", 0);
      $("#VS_" + column + "_VehicleModel").prop("selectedIndex", 0);
      $("#VS_" + column + "_Variant").prop("selectedIndex", 0);

      $(this)
        .parents(".column.thirds")
        .addClass("hidden");
      checkVehicleNumber();
    });
  }
}
