"use strict";

export class TopPerformers {
  constructor() {
    //Adds carousel to items present
    $(document).ready(function() {
      $(".top-performers .table-wrapper").slick({
        dots: true,
        infinite: true,
        slidesToShow: 2,
        nextArrow: ".top-performers__btn__next",
        prevArrow: ".top-performers__btn__prev",
        responsive: [
          {
            breakpoint: 930,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          }
        ]
      });
    });
  }
}
