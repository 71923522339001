"use strict";

export class ResultsPageResultsBlock {
  constructor() {
    const ResultsPageComparerMenuBtn = document.querySelector(
      ".result-page-search-block__menu__btn"
    );
    // Adds slider when results page search block is clicked
    $("body").on("click", ".result-page-search-block__menu__btn", function(e) {
      e.preventDefault();

      if ($(this).hasClass("Slide-active")) {
        $(this)
          .parent()
          .siblings(".inner-dropdown")
          .slideUp();
        ResultsPageComparerMenuBtn.setAttribute("aria-expanded", "false");
        $(this)
          .removeClass("Slide-active")
          .removeClass("rotate-btn");

        $(this)
          .parent()
          .parent()
          .addClass("Remove-bottom-padding");
      } else {
        $(this)
          .parent()
          .siblings(".inner-dropdown")
          .slideDown();
        ResultsPageComparerMenuBtn.setAttribute("aria-expanded", "true");
        $(this)
          .addClass("Slide-active")
          .addClass("rotate-btn");
        $(this)
          .parent()
          .parent()
          .removeClass("Remove-bottom-padding");
      }
    });

    ////////////////////////
    // old original code for email
    function getUserDataFromDlg(
      titleStr,
      promptStr,
      dataDest,
      dataRequired,
      placeHolderText,
      inputTypeCode
    ) {
      var dfd = new jQuery.Deferred(),
        isDate = inputTypeCode == "d",
        i = 0,
        htmlLines = [];

      htmlLines[i++] = '<div class="form-item column quaters">';
      htmlLines[i++] =
        '<span id="errMsgSpan" class="" validation-for="user-input-field"></span>';
      htmlLines[i++] = '<label for="user-input-field">';
      htmlLines[i++] = promptStr;
      htmlLines[i++] = "</label>";
      htmlLines[i++] = isDate ? '<div class="date-picker-wrap">' : "";
      htmlLines[i++] =
        '<input name="user-input-field" type="text" class="userInputField';
      htmlLines[i++] = isDate ? ' date-picker__js"' : '"';
      htmlLines[i++] = ' style="width:300px;" placeholder=';
      htmlLines[i++] =
        placeHolderText == null
          ? '"" autofocus="true"'
          : '"' + placeHolderText + '"' + " />";
      htmlLines[i++] = (isDate ? "</div>" : "") + "</div></form>";

      $(".dialogueConfirm__js").html(htmlLines.join(""));

      if (isDate) {
        $(".date-picker__js").datepicker({
          beforeShow: function(input, inst) {
            $("#ui-datepicker-div").addClass("ll-skin-latoja");
          },
          dateFormat: "dd/mm/yy",
          showOn: "both",
          buttonImage: "../assets/images/content/date-picker-icon.png",
          showButtonPanel: false
        });
        $(".userInputField").datepicker("setDate", new Date());
      }

      $(".dialogueConfirm__js").dialog({
        title: titleStr,
        resizable: false,
        height: 400,
        width: 400,
        modal: true,
        position: ["center", 60],
        buttons: {
          OK: function() {
            var enteredVal = $(".userInputField").val();
            var spanElem = $("#errMsgSpan");
            var tempDate = null;
            var dateParts = null;

            if (isDate) {
              dateParts = enteredVal.split("/");
              var dteDay = parseInt(dateParts[0]),
                dteMonth = parseInt(dateParts[1]),
                dteYear = parseInt(dateParts[2]);

              tempDate = new Date(dteYear, dteMonth - 1, dteDay);
              if (
                dteDay != tempDate.getDate() ||
                dteMonth != tempDate.getMonth() + 1 ||
                dteYear != tempDate.getFullYear()
              ) {
                tempDate = null;
              }
            }

            spanElem.html("");
            spanElem.removeClass("field-validation-error");
            spanElem.addClass("field-validation-valid");

            if (
              !dataRequired ||
              (!isDate &&
                enteredVal != null &&
                enteredVal != undefined &&
                enteredVal != "") ||
              (isDate && tempDate != null)
            ) {
              dataDest.val(enteredVal);
              $(".userInputField").datepicker("hide");
              $(this).dialog("close");
              dfd.resolve(true);
            } else {
              spanElem.html(
                "A value is required" +
                  (isDate ? " and must be a valid date" : "") +
                  "."
              );
              spanElem.addClass("field-validation-error");
              spanElem.removeClass("field-validation-valid");
            }
          },
          Cancel: function() {
            if (isDate) {
              $(".userInputField").datepicker("hide");
            }
            $(this).dialog("close");
            dfd.resolve(false);
          }
        }
      });

      return dfd.promise();
    }
    /////////////////////////

    /////////////////////////
    //old origial code for pdf download
    function SetSearchResultOutputValues(outputType, currentOnly) {
      var emailAddress;

      if (outputType == 3) {
        emailAddress = $("#advOutputAddress").val(); //set by get data dialog called from _searchCompareBannerPartial view
        if (emailAddress == null || emailAddress == "") {
          alert("Unable to send email - no address found.");
          return false;
        }
        $("#spOutputAddress").val(emailAddress);
      }

      $("#OutputFormat").val(outputType);
      $("#OutputFormatSP").val(outputType);

      if (currentOnly) {
        $("#submitType3").click();
      } else {
        $("#submitSP").click(); //Adv Search slideout to include usage details in output.
      }

      $("#OutputFormat").val(-1);
      $("#OutputFormatSP").val(-1);
    }
    //////////////////////////

    //pdf download button click
    $("body").on("click", ".pdf-download", function(e) {
      e.preventDefault();
      SetSearchResultOutputValues(1, false);
    });

    $("body").on("click", ".csv", function(e) {
      e.preventDefault();
      SetSearchResultOutputValues(2, false);
    });

    $("body").on("click", ".print", function(e) {
      e.preventDefault();
      window.print();
    });

    $("body").on("click", ".email", function(e) {
      e.preventDefault();
      getUserDataFromDlg(
        "Email Address",
        "Enter destination address",
        $("#advOutputAddress")
      );
      $("#advOutputAddress");
      // $.when(getUserDataFromDlg('Email Address', 'Enter destination address',
      //  $('#advOutputAddress'), true))
      //  .then(function (userResponse) {
      //     if(userResponse) {SetSearchResultOutputValues(3, false)}
      //   });
    });

    // match height for table head cells
    $(".table-item-field.title").matchHeight({
      byRow: false,
      property: "height",
      target: null,
      remove: false
    });

    // match height for table cells
    for (let i = 1; i < 11; i++) {
      //matching cells in every row excludes the first cell
      let numOfCells = $(".table-item .table-item-field:nth-child(" + i + ")")
        .last()
        .find(".cell").length;
      if (numOfCells > 1) {
        for (let x = 1; x <= numOfCells; x++) {
          $(".table-item .table-item-field:nth-child(" + i + ")")
            .find(".cell:nth-child(" + x + "):not(.no-match-height)")
            .matchHeight({
              byRow: false,
              property: "height",
              target: null,
              remove: false
            });
          // console.log(numOfCells,$(".table-item .table-item-field:nth-child(" + i + ")").find(".cell:nth-child(" + x + "):not(.no-match-height)"));
        }
      } else {
        //matching cells in every row includes the first cell
        for (let x = 1; x <= numOfCells; x++) {
          $(".table-item .table-item-field:nth-child(" + i + ")")
            .find(".cell:nth-child(" + x + ")")
            .matchHeight({
              byRow: false,
              property: "height",
              target: null,
              remove: false
            });
        }
      }

      $(".table-item .table-item-field:nth-child(" + i + ")").matchHeight({
        byRow: false,
        property: "height",
        target: null,
        remove: false
      });
    }

    // when dropdown is changed
    $("body").on("change", ".vehicle-dropdown", function(e) {
      e.preventDefault();
      let selectedVehicle = $(this).val();
      $(this)
        .parent()
        .siblings(".vehicle-item")
        .addClass("hidden");
      $(this)
        .parent()
        .siblings("." + selectedVehicle)
        .removeClass("hidden");
      setBackgroundColour();
    });

    //calculate average
    //================
    // // to get the first number in a string
    // function getFirstNumber(string) {
    //   let $firstCell = "";
    //   for (let i = 0; i < string.length; i++) {
    //     if (string.charAt(i) === "$") continue;
    //     if (
    //       !(
    //         (string.charAt(i) >= "0" && string.charAt(i) <= "9") ||
    //         string.charAt(i) === "."
    //       )
    //     )
    //       return parseFloat($firstCell);

    //     $firstCell += string.charAt(i);
    //   }
    //   return parseFloat($firstCell);
    // }

    // // set scale class on the item
    // function setScale(average, min, diff, $cell) {
    //   let cellVal = getFirstNumber($cell.text());
    //   let scale1 = min + diff * 1;
    //   let scale2 = min + diff * 2;
    //   let scale3 = min + diff * 3;
    //   let scale4 = min + diff * 4;
    //   let scale5 = min + diff * 5;

    //   if (cellVal == average) {
    //     $cell.addClass("scale3");
    //     $cell.append('<span class="scale-text"> [Average]</span>');
    //   } else {
    //     //scale1 is best, scale5 is the worst
    //     if (cellVal >= min && cellVal < scale1) {
    //       $cell.addClass("scale1");
    //       $cell.append(
    //         '<span class="scale-text"> [Better than average]</span>'
    //       );
    //     } else if (cellVal >= scale1 && cellVal <= scale2) {
    //       $cell.addClass("scale2");
    //       $cell.append(
    //         '<span class="scale-text"> [Slightly better than average]</span>'
    //       );
    //     } else if (cellVal >= scale2 && cellVal <= scale3) {
    //       $cell.addClass("scale3");
    //       $cell.append('<span class="scale-text"> [Average]</span>');
    //     } else if (cellVal >= scale3 && cellVal <= scale4) {
    //       $cell.addClass("scale4");
    //       $cell.append(
    //         '<span class="scale-text"> [Slightly worse than average]</span>'
    //       );
    //     } else if (cellVal >= scale4 && cellVal <= scale5) {
    //       $cell.addClass("scale5");
    //       $cell.append('<span class="scale-text"> [Worse than average]</span>');
    //     }
    //   }
    // }

    // // compute scale background
    // function setBackgroundColour() {
    //   $(".to-compare")
    //     .removeClass("scale1")
    //     .removeClass("scale2")
    //     .removeClass("scale3")
    //     .removeClass("scale4")
    //     .removeClass("scale5");

    //   $(".scale-text").remove();
    //   let toCompareCellCounter = 0;
    //   let $tableItemFields = $(".vehicle-item:not(.hidden)")
    //     .first()
    //     .find(".table-item-field");
    //   for (let x = 0; x < $tableItemFields.length; x++) {
    //     // item fields
    //     let $toCompareCells = $($tableItemFields.get(x)).find(
    //       ".cell.to-compare"
    //     );
    //     for (let i = 0; i < $toCompareCells.length; i++) {
    //       //tocompare cells
    //       let vehicleCounter = 0;
    //       let $firstCell = $(
    //         $($(".vehicle-item:not(.hidden)").get(vehicleCounter))
    //           .find(".cell.to-compare")
    //           .get(toCompareCellCounter)
    //       );
    //       let sum = 0;
    //       let $maxValCell = $firstCell;
    //       let $minValCell = $firstCell;

    //       for (let y = 0; y < $(".vehicle-item:not(.hidden)").length; y++) {
    //         let $cell = $(
    //           $($(".vehicle-item:not(.hidden)").get(vehicleCounter))
    //             .find(".cell.to-compare")
    //             .get(toCompareCellCounter)
    //         );
    //         vehicleCounter++;

    //         if (
    //           getFirstNumber($cell.text()) > getFirstNumber($maxValCell.text())
    //         ) {
    //           $maxValCell = $cell;
    //           $cell.addClass("scale");
    //         } else if (
    //           getFirstNumber($cell.text()) < getFirstNumber($minValCell.text())
    //         ) {
    //           $minValCell = $cell;
    //         }
    //         sum += getFirstNumber($cell.text());
    //       }

    //       let averageVal = sum / vehicleCounter;
    //       let minVal = getFirstNumber($minValCell.text());
    //       let maxVal = getFirstNumber($maxValCell.text());
    //       let maxMinDiff = (maxVal - minVal) / 5;

    //       vehicleCounter = 0;
    //       for (let y = 0; y < $(".vehicle-item:not(.hidden)").length; y++) {
    //         let $cell = $(
    //           $($(".vehicle-item:not(.hidden)").get(vehicleCounter))
    //             .find(".cell.to-compare")
    //             .get(toCompareCellCounter)
    //         );
    //         vehicleCounter++;
    //         setScale(averageVal, minVal, maxMinDiff, $cell);
    //       }

    //       toCompareCellCounter++;
    //       // console.log("min",minVal, "max",maxVal, "aver", averageVal, "diff", maxMinDiff)
    //     }
    //   }
    // }
    //////==================

    // setBackgroundColour();
  }
}
