"use strict";

export class News {
  constructor() {
    //Checks if the page loaded has news content
    if ($(".news-homepage").length) {
      // Makes carousel for news items
      $(document).ready(function() {
        $(".news__section").slick({
          dots: true,
          infinite: true,
          slidesToShow: 4,
          slidesToScroll: 4,
          nextArrow: ".news__btn__next",
          prevArrow: ".news__btn__prev",
          //Changes the amount of items shown for each view width
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 780,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });
      });
    }
  }
}
