"use strict";

export class Fuel_and_CO2_calculator {
  constructor() {
    // Checks if fuel and co2 calculator is on current page
    if ($(".fuel-and-CO2-calculator").length) {
      const fuelCO2Button = document.querySelector(
        ".fuel-and-CO2-calculator__btn"
      );
      const fuelCO2Values = document.querySelector(
        ".fuel-and-CO2-calculator__value"
      );

      const divEmission = document.querySelector(
        ".fuel-and-CO2-calculator__emission"
      );
      const divConsumption = document.querySelector(
        ".fuel-and-CO2-calculator__consumption"
      );
      const divTravelled = document.querySelector(
        ".fuel-and-CO2-calculator__travelled"
      );
      const divPrice = document.querySelector(
        ".fuel-and-CO2-calculator__price"
      );

      // Checks to see when the calculate button is clicked and gets input from users "input" field
      fuelCO2Button.addEventListener("click", function(e) {
        var valueEmission = document.querySelector(
          ".fuel-and-CO2-calculator__emission"
        ).value;
        var valueConsumption = document.querySelector(
          ".fuel-and-CO2-calculator__consumption"
        ).value;
        var valueTravelled = document.querySelector(
          ".fuel-and-CO2-calculator__travelled"
        ).value;

        var valuePrice = document.querySelector(
          ".fuel-and-CO2-calculator__price"
        ).value;

        var fuelCost, annualCO2;
        e.preventDefault();

        divEmission.classList.remove("error-active");
        divConsumption.classList.remove("error-active");
        divTravelled.classList.remove("error-active");
        divPrice.classList.remove("error-active");
        fuelCO2Values.classList.remove("fuel-and-CO2-calculator__value-active");

        // Makes sure all the fields are active and not empty
        if (valueEmission && valueConsumption && valueTravelled && valuePrice) {
          //Calculates fuel cost and annual CO2
          fuelCO2Values.classList.add("fuel-and-CO2-calculator__value-active");

          fuelCost = (valueTravelled / 10000) * valuePrice * valueConsumption;
          fuelCost = "$" + Math.round(fuelCost * 100) / 100;
          annualCO2 = (valueTravelled / 1000000) * valueEmission;
          annualCO2 = Math.round(annualCO2 * 100) / 100 + " tonnes";

          $(".fuel-and-CO2-calculator__value__fuel-cost").html(fuelCost);
          $(".fuel-and-CO2-calculator__value__annual-CO2").html(annualCO2);
        }
        // Else add error active states to fields that are empty
        else {
          if (!valueEmission) {
            divEmission.classList.add("error-active");
          }
          if (!valueConsumption) {
            divConsumption.classList.add("error-active");
          }
          if (!valueTravelled) {
            divTravelled.classList.add("error-active");
          }
          if (!valuePrice) {
            divPrice.classList.add("error-active");
          }
        }
      });
      // Moves text above input when user types in a number
      $(".fuel-and-CO2-calculator__box input").on(
        "change keyup keydown",
        function(e) {
          var elem = $(this);
          if (elem.val()) {
            elem.closest(".fuel-and-CO2-calculator__box").addClass("hasValue");
          } else {
            elem
              .closest(".fuel-and-CO2-calculator__box")
              .removeClass("hasValue");
          }
        }
      );
    }
  }
}
