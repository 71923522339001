"use strict";

export class FeedbackBox {
  constructor() {
    //Checks if the page loaded is a feedback page
    if ($(".feedback-box").length) {
      $(".feedback-box__input").on("change keyup keydown", function(e) {
        var elem = $(this);
        if (elem.val()) {
          elem.closest(".feedback-box__box").addClass("hasValue");
        } else {
          elem.closest(".feedback-box__box").removeClass("hasValue");
        }
      });
      //Shows additional fields to enter when radio is checked
      $('input[name="Useful"]').on("change", function() {
        $(".feedback-box__box").show("show");
        $(".feedback-box__btn").show("show");
        $(".feedback-box__text__2").css("display", "inline");
        $(".feedback-box__contact").css("display", "inline");
      });
      //Shows text when "No" is clicked
      $(".feedback-box__radio__no").on("click", function(e) {
        $(".feedback-box__radio__no__text").show("show");
        $(".feedback-box__radio__yes__text").hide("hide");
      });
      //Shows text when "Yes" is clicked
      $(".feedback-box__radio__yes").on("click", function(e) {
        $(".feedback-box__radio__yes__text").show("show");
        $(".feedback-box__radio__no__text").hide("hide");
      });
    }
  }
}
