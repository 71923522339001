"use strict";

export class GlobalElements {
  constructor() {
    const sectionGeneralLeftMenuBtn = document.querySelector(
      ".section-general__left__btn"
    );
    // Adds slide-active dropdown to side links
    $("body").on("click", ".section-general__left__btn", function(e) {
      e.preventDefault();

      if ($(this).hasClass("Slide-active")) {
        $(this)
          .parent()
          .siblings(".section-general__left__dropdown")
          .slideUp();
        $(this)
          .parent()
          .addClass("green-box__line");
        sectionGeneralLeftMenuBtn.setAttribute("aria-expanded", "false");
        $(this)
          .removeClass("Slide-active")
          .removeClass("section-rotate-btn");
      } else {
        $(this)
          .parent()
          .siblings(".section-general__left__dropdown")
          .slideDown();
        $(this)
          .parent()
          .removeClass("green-box__line");
        sectionGeneralLeftMenuBtn.setAttribute("aria-expanded", "true");
        $(this)
          .addClass("Slide-active")
          .addClass("section-rotate-btn");
      }
    });
  }
}
