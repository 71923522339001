"use strict";

export class Header {
  constructor() {
    //Checks header pop up has shown before
    if ($.cookie(".popup__header") != "header-card-seen") {
      $.cookie(".popup__header", "header-card-seen", { expires: 1, path: "/" }); // Set as per day
      $(".header__card")
        .delay(1000)
        .fadeIn();
      $(".header__card__exit").click(function(
        e // You are clicking the close button
      ) {
        $(".header__card").fadeOut(); // Now the pop up is hidden.
      });

      $(".header__card").click(function(e) {
        $(".header__card").fadeOut();
      });
    }
  }
}
