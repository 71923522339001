"use strict";

export class Search {
  constructor() {
    //Add compare car block when additional item is clicked
    $("body").on("click", ".search__additional__add", function(e) {
      e.preventDefault();
      $(".search .column.thirds.hidden")
        .first()
        .removeClass("hidden");
    });
    var valueYear1,
      valueMake1,
      valueYear2,
      valueMake2,
      valueYear3,
      valueMake3,
      rowCheck1,
      rowCheck2,
      rowCheck3;
    const divYear1 = document.querySelector("#VS_0_SelectedYearStart");
    const divMake1 = document.querySelector("#VS_0_SelectedManufacturer");
    const divYear2 = document.querySelector("#VS_1_SelectedYearStart");
    const divMake2 = document.querySelector("#VS_1_SelectedManufacturer");
    const divYear3 = document.querySelector("#VS_2_SelectedYearStart");
    const divMake3 = document.querySelector("#VS_2_SelectedManufacturer");
    // Activates when search button is clicked
    $("body").on("click", ".btn__search", function(e) {
      e.preventDefault();
      valueYear1 = document.querySelector("#VS_0_SelectedYearStart").value;
      valueMake1 = document.querySelector("#VS_0_SelectedManufacturer").value;
      valueYear2 = document.querySelector("#VS_1_SelectedYearStart").value;
      valueMake2 = document.querySelector("#VS_1_SelectedManufacturer").value;
      valueYear3 = document.querySelector("#VS_2_SelectedYearStart").value;
      valueMake3 = document.querySelector("#VS_2_SelectedManufacturer").value;
      divYear1.classList.remove("error-active-search");
      divMake1.classList.remove("error-active-search");
      divYear2.classList.remove("error-active-search");
      divMake2.classList.remove("error-active-search");
      divYear3.classList.remove("error-active-search");
      divMake3.classList.remove("error-active-search");
      rowCheck1 = true;
      rowCheck2 = true;
      rowCheck3 = true;
      // Checks to make sure both Year and Make has been selcted by user
      // If either has not been selected, show error state and do not proceed
      if (valueYear1 !== "-1" && valueMake1 !== "-1") {
        rowCheck1 = true;
      } else {
        if (valueYear1 === "-1") {
          divYear1.classList.add("error-active-search");
          rowCheck1 = false;
        }
        if (valueMake1 === "-1") {
          divMake1.classList.add("error-active-search");
          rowCheck1 = false;
        }
      }
      // Checks to make sure both Year and Make has been selcted by user for second row
      // If either has not been selected, show error state and do not proceed
      if ($("#search-row-2").hasClass("hidden") === false) {
        if (valueYear2 !== "-1" && valueMake2 !== "-1") {
          rowCheck2 = true;
        } else {
          if (valueYear2 === "-1") {
            divYear2.classList.add("error-active-search");
            rowCheck2 = false;
          }
          if (valueMake2 === "-1") {
            divMake2.classList.add("error-active-search");
            rowCheck2 = false;
          }
        }
      }
      // Checks to make sure both Year and Make has been selcted by user for third row
      // If either has not been selected, show error state and do not proceed
      if ($("#search-row-3").hasClass("hidden") === false) {
        if (valueYear3 !== "-1" && valueMake3 !== "-1") {
          rowCheck3 = true;
        } else {
          if (valueYear3 === "-1") {
            divYear3.classList.add("error-active-search");
            rowCheck3 = false;
          }
          if (valueMake3 === "-1") {
            divMake3.classList.add("error-active-search");
            rowCheck3 = false;
          }
        }
      }
      // If all rows has Year and Make selected, proceed with search
      if (rowCheck1 === true && rowCheck2 === true && rowCheck3 === true) {
        $("#SearchTypeVC").val("2");
        $(".search__form").submit();
      }
    });

    //importing old code
    //////////////////////////////////////////////////////////////////////
    // Functions for populating vehicle dropdown lists                  //
    // Make, Model and Variant filtered by year.                        //
    //////////////////////////////////////////////////////////////////////
    function PopulateSelect(selectData, target, prompt, setVal) {
      var itemHTML =
        prompt != null
          ? '<option value="-1">' + "" + "</option>"
          : '<option value="-1">Select</option>';

      if (selectData != null) {
        if (
          selectData.length == 1 &&
          selectData[0].Value == "" &&
          selectData[0].Text == ""
        ) {
          return;
        }
        $.each(selectData, function(index, item) {
          itemHTML +=
            '<option value="' + item.Value + '">' + item.Text + "</option>";
        });
      }
      target.html(itemHTML);
      if (setVal != null) {
        target.val(setVal);
      }
      if (target.val() == null || target.val() == undefined) {
        target.val(-1);
      }
    }

    function SetDropdownChangeEvents(i, sourceLink, currentOnly) {
      var headTagId = "#VS_" + i,
        yearStart = $(headTagId + "_SelectedYearStart"),
        yearEnd = $(headTagId + "_SelectedYearEnd"),
        manufacturer = $(headTagId + "_SelectedManufacturer"),
        model = $(headTagId + "_VehicleModel"),
        variant = $(headTagId + "_Variant"),
        isSingleSearchYear = i != 4 && i < 6; //Compare item (index 0 - 2), Basic search panel (index 3) and Add to Compare (index 5) doesn't have an end year displayed therefore always equals start year.
      //index 4 Adv search panel; index 6 and 7 admin (non-public) Pending and general Record search, all have start and end year.

      if (currentOnly == null) {
        currentOnly = false;
      }
      yearStart.change(function(e) {
        if (isSingleSearchYear) {
          yearEnd.val(yearStart.val());
        }
        SetManufacturers(
          false,
          null,
          sourceLink,
          yearStart,
          yearEnd,
          manufacturer,
          model,
          variant,
          currentOnly,
          i < 6
        );
      });

      yearEnd.change(function(e) {
        yearStart.change();
      });

      manufacturer.change(function(e) {
        if (isSingleSearchYear) {
          yearEnd.val(yearStart.val());
        }
        if (this.value != null) {
          SetModels(
            false,
            null,
            sourceLink,
            yearStart,
            yearEnd,
            this.value,
            model,
            variant,
            currentOnly
          );
        }
      });

      model.change(function(e) {
        if (isSingleSearchYear) {
          yearEnd.val(yearStart.val());
        }
        if (this.value != null) {
          SetVariants(
            false,
            null,
            sourceLink,
            yearStart,
            yearEnd,
            manufacturer.val(),
            this.value,
            variant,
            currentOnly
          );
        }
      });
    }

    function SetManufacturers(
      tabSyncInOperation,
      sourceField,
      sourceLink,
      yearStart,
      yearEnd,
      manufacturer,
      model,
      variant,
      currentOnly,
      isPublicSearch
    ) {
      var tabSyncSource = null,
        endYearVal = GetEndYear(yearStart, yearEnd);

      if (tabSyncInOperation == true) {
        tabSyncSource = sourceField.val();
      }

      if (tabSyncInOperation == false) {
        manufacturer.value = "-1";
        manufacturer.html('<option value="-1"></option>');
        model.html('<option value="-1"></option>');
        model.value = "-1";
        variant.html('<option value="-1"></option>');
        variant.value = "-1";
      }

      $.ajax({
        url: sourceLink,
        type: "POST",
        data: {
          startYear: yearStart.val(),
          endYear: endYearVal,
          manufacturerId: "-1",
          showCurrentOnly: currentOnly,
          isPublicSearchOption: isPublicSearch
        },
        success: function(data) {
          PopulateSelect(data, manufacturer, "Make", tabSyncSource);
        },
        error: function(xhr) {
          PopulateSelect(null, manufacturer, "Make");
        }
      });
    }

    function SetModels(
      tabSyncInOperation,
      sourceField,
      sourceLink,
      yearStart,
      yearEnd,
      selectedMake,
      model,
      variant,
      currentOnly
    ) {
      var endYearVal = GetEndYear(yearStart, yearEnd),
        tabSyncSource = null;

      if (tabSyncInOperation == true) {
        tabSyncSource = sourceField.val();
      }
      if (tabSyncInOperation == false) {
        model.value = "-1";
        variant.html('<option value="-1"></option>');
        variant.value = "-1";
      }

      if (selectedMake != -1) {
        $.ajax({
          url: sourceLink,
          type: "POST",
          data: {
            startYear: yearStart.val(),
            endYear: endYearVal,
            manufacturerId: selectedMake,
            showCurrentOnly: currentOnly
          },
          success: function(data) {
            PopulateSelect(data, model, "Model", tabSyncSource);
          },
          error: function(xhr) {
            PopulateSelect(null, model, "Model");
          }
        });
      } else {
        PopulateSelect(null, model, "Model", tabSyncSource);
      }
    }

    function SetVariants(
      tabSyncInOperation,
      sourceField,
      sourceLink,
      yearStart,
      yearEnd,
      selectedMake,
      selectedModel,
      variant,
      currentOnly
    ) {
      var endYearVal = GetEndYear(yearStart, yearEnd),
        tabSyncSource = null;

      if (tabSyncInOperation == true) {
        tabSyncSource = sourceField.val();
      }

      if (selectedMake != -1 && selectedModel != -1) {
        $.ajax({
          url: sourceLink,
          type: "POST",
          data: {
            startYear: yearStart.val(),
            endYear: endYearVal,
            manufacturerId: selectedMake,
            modelName: selectedModel,
            showCurrentOnly: currentOnly
          },
          success: function(data) {
            if (tabSyncInOperation == false) {
              variant.value = "-1";
            }
            PopulateSelect(data, variant, "Variant", tabSyncSource);
          },
          error: function(xhr) {
            PopulateSelect(null, variant, "Variant");
          }
        });
      } else {
        PopulateSelect(null, variant, "Variant", tabSyncSource);
      }
    }

    function GetEndYear(yearStart, yearEnd) {
      if (yearEnd == null || !$.isNumeric(yearEnd.val())) {
        return yearStart.val();
      }

      if (yearEnd.val() == -1) {
        return new Date().getFullYear();
      }

      if (yearEnd.val() < yearStart.val()) {
        return yearStart.val();
      } else {
        return yearEnd.val();
      }
    }

    SetDropdownChangeEvents(0, "/Vehicle/GetNamesForSelectList"); //Adv. Search Tab1.
    SetDropdownChangeEvents(1, "/Vehicle/GetNamesForSelectList"); //Adv. Search Tab2.
    SetDropdownChangeEvents(2, "/Vehicle/GetNamesForSelectList"); //Adv. Search Tab2.
    SetDropdownChangeEvents(4, "/Vehicle/GetNamesForSelectList"); //search vehicle by feature
  }
}
